import React from 'react';

class SliderControl extends React.Component {

	render() {
		return (
			<div className="slider-control">
				<div className="slider-button" onClick={this.props.previousSlide}>&nbsp;</div>
				<div className="slider-button" onClick={this.props.nextSlide}>&nbsp;</div>
			</div>
		)
	};
}

export default SliderControl;