import React from 'react';
import SliderControl from './slider-control';
import './sliders.css'
import './hooks.css'
import Link from 'gatsby-link';

import './homeslider/homeslider.css';

// import './font/fontawesome-webfont.eot'
// import './font/fontawesome-webfont.svg'
// import './font/fontawesome-webfont.ttf'
// import './font/fontawesome-webfont.woff'
// import './font/fontawesome-webfont.ttf'
// import './font/FontAwesome.otf'

export default class Sliders extends React.Component {

	render() {

		 var Carousel = require('nuka-carousel');

		var Decorators = [{
			component: SliderControl,
			position: 'BottomCenter'
		  }]; 

		return (

			<div>
				 <div id="top_column" className="center_column col-xs-12 d-none d-sm-block">
					<div id="homepage-slider" className="hidden-xs hidden-sm hidden-md">
						<Carousel autoplay={true} swiping={true} wrapAround={true} decorators={Decorators}>
							<div>
								<Link to="/">
									<img src="/img/slider/1/1.png" width="1170" height="584" alt="Модель Виктория" />
								</Link>
								<div className="homeslider-description">
									<h2>Creative</h2>
								</div>
							</div>

							<div>
								<Link to="/">
									<img src="/img/slider/1/2.png" width="1170" height="584" alt="Модель Инна" />
								</Link>
								<div className="homeslider-description"><h2>sexy</h2>
									
								</div>
							</div>
							<div>
								<Link to="">
									<img src="/img/slider/1/3.png" width="1170" height="584" alt="Модель Василия" />
								</Link>
								<div className="homeslider-description"><h2>beauty</h2>
								
								</div>
							</div>
						</Carousel>
					</div>

				</div> 

				{/* <div className="row banner-square-container">

					<div className="col">
						<div className="banner-square-item">
							
							<div className="banner-square-image-container">
							<img src="/img/banners/square/1.png" className="" />
							</div>

							<div className="banner-square-image-sub">
							xxxsasx
							</div>
						</div>	
					</div>					
					<div className="col"></div>					
					<div className="col"></div>					
				</div>	 */}

				<div id="htmlcontent_top">
					<ul className="htmlcontent-home clearfix row list-unstyled">
						<li className="htmlcontent-item-1 col-xs-4">
							<Link to="/narashchivanie-volos" className="item-link">
								<div className="imgBox"><img src="/img/banners/square/1.png" className="item-img" alt="Профессиональное наращивание" width="100%" height="100%" />
									<div className="mask"></div></div>
								<div className="item-html">
									Профессиональное наращивание
				</div>
							</Link>
						</li>
						<li className="htmlcontent-item-2 col-xs-4">
							<Link to="/okrashivanie-volos" className="item-link">
								<div className="imgBox"><img src="/img/banners/square/2.png" className="item-img" alt="Сложное окрашивание" width="100%" height="100%" />
									<div className="mask"></div></div>
								<div className="item-html">
									Сложное окрашивание
				</div>
							</Link>
						</li>
						<li className="htmlcontent-item-3 col-xs-4">
							<Link to="/prajs" className="item-link">
								<div className="imgBox"><img src="/img/banners/square/3.png" className="item-img" alt="Наш прайс" width="100%" height="100%" />
									<div className="mask"></div></div>
								<div className="item-html">
									Узнай наши расценки
				</div>
							</Link>
						</li>
					</ul>
				</div> 
			</div>

		)
	};
}