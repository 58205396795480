import React from 'react';
import Link from 'gatsby-link'

const Banner = () => {
	return (
		<div id="htmlcontent_home">
			<ul className="list-unstyled">
				<li className="">
				<Link to="/salon" className="item-link">
						<div className="imgBox">
							<img src="/img/banners/lips.png" className="item-img" alt="Перезвоните!" width="100%" height="100%" />
							<div className="mask"></div></div>
						<div className="item-html">
							<p>Мы исполняем ваши мечты</p>
							<h2>Как к нам попасть?!</h2>
						</div>
					</Link>
				</li>
			</ul>
		</div>
	);
}

export default Banner;