import React from 'react';

const InfoLine = () => {

	return (
		<div>

 {/* <div id={instafeedTarget}>
       <Instafeed
        limit='5'
        ref='instafeed'
        resolution='standard_resolution'
        sortBy='most-recent'
        target={instafeedTarget}
        template=''
        userId='1091447760'
        clientId='821691e6fb8243f19d61f39fbe120848'
        accessToken='1091447760.821691e.8cd67ee384dc4ceb884f452ff5936456'
      /> 
    </div>  */}

		</div>
	);
}

export default InfoLine;