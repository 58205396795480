import React from 'react'
import TemplateWrapper from '../components/layout';
import { Helmet } from "react-helmet";
import Sliders from '../components/Main/sliders';
import Banner from '../components/Main/banner';
import InfoLine from '../components/Main/info-line';

const IndexPage = () => (
  <TemplateWrapper>
  <div>
    <Helmet>
      <title>Профессиональное наращивание и окрашивание волос в Москве!</title>
      <meta name="description" content="Идеальные волосы - наша работа. Приглашаем Вас испытать лучшее наращивание волос в Москве. Преображайтесь вместе с нами!" />
      <meta name="keywords" content="Премиум наращивание волос, наращивание волос люкс, наращивание волос москва, ленточное наращивание, капсульное наращивание, афронаращивание, голливудское наращивание, окрашивание волос, окраска волос, цены, купить волосы, капсулы для наращивания, мастер по наращиванию, лучший мастер в москве, акции на наращивание волос" />
    </Helmet>

    <Sliders />

    <h1 className="hm-title-1 pt-3">Насколько важны красивые волосы для девушки?</h1>
    <p>
      Красивые, ухоженные волосы — это важная составляющая женского образа. Если прическа выглядит неопрятно и не походит к гардеробу, возникает ощущение легкой небрежности, неряшливости, сразу портится впечатление от общения с человеком. Каждой женщине важно не просто следить за состоянием волос, а использовать все возможные способы поддержания их красоты, густоты и естественного блеска.</p>
    <p>Бывает такое, что у девушки пышные и здоровые волосы, а она не пользуется этим природным даром для раскрытия своего внешнего потенциала: носит незаметные прически, не экспериментирует с модными парикмахерскими техниками. Нередко можно встретить женщин с редкими волосами, но которые они сумели преобразить с помощью выгодного цвета, эффектной стрижки или необходимой длины. </p>
    <p>Если вы пока не разбираетесь в современных видах окрашивания, наращивания волос, не знаете, какую прическу лучше всего подобрать под ваш тип лица и образ в одежде, просто доверьтесь опытному стилисту. Профессионал помогает женщине разобраться в ее предпочтениях и умело воплотить в реальность все мечты, касающиеся прически, густоты и цвета волос.</p>
{/*     
    <img src="/img/photos/43.jpg" className="img-fluid float-md-right img-thumbnail" alt="Наращивание волос" /> */}

    <Banner />
    <InfoLine />
  </div>
  </TemplateWrapper>
)

export default IndexPage
